
/* eslint-disable */
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Home',
  components: {
  },
  mounted() {
  },
});

